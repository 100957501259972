<template>
    <div class="menu-item g-col-12 g-col-md-6">
        <div class="menu-item-title mb-1">{{ item.name }}</div>

        <div v-if="item.description || item.cost.price" class="menu-item-description">
            {{ item.description }}
            <template v-if="item.description && item.cost.price">&nbsp;//&nbsp;</template>
            <span>
                <template v-if="item.cost.price">{{ item.cost.price }}</template>
            </span>
        </div>

        <ul v-if="item.cost.options" class="list-unstyled mt-1 text-yellow">
            <li v-for="option in item.cost.options">
                <template v-if="option.name">{{ option.name }}</template>
                <template v-if="option.name && option.price">&nbsp;//&nbsp;</template>
                <template v-if="option.price">{{ option.price }}</template>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
};
</script>
