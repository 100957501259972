<template>
    <div class="row pt-6 pt-xl-8 gx-8" id="top">
        <div class="col-12 col-xl-3">
            <div class="h-100">
                <MenuSidebar :menu="menu" :downloads="downloads" />
            </div>
        </div>
        <div class="col-12 col-xl-9 mt-5 mt-xl-0">
            <div
                class="menus-panel"
                data-bs-spy="scroll"
                data-bs-target="#menu-nav-items"
                data-bs-root-margin="0px 0px -87.25%"
            >
                <MenuSection
                    v-for="section in menu.sections"
                    :section="section"
                    :key="'section-' + section.id"
                    :id="section.slug"
                    :image="getSectionImage(section.id)"
                />
            </div>

            <div class="menus-footer pt-4 pt-xl-6 border-top text-center text-yellow">
                <slot></slot>
            </div>

            <div class="mt-4 pb-4 d-block d-xl-none">
                <a href="#top" class="menus-to-top d-flex flex-column align-items-center justify-content-center">
                    <div class="menus-to-top-arrow border rounded rounded-circle d-flex">
                        <inline-svg src="/app/themes/littles/images/arrow.svg"></inline-svg>
                    </div>

                    <span class="menus-to-top-text text-uppercase mt-2">Back to the top</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import MenuSection from './Section.vue';
import MenuSidebar from './Sidebar.vue';

export default {
    components: { MenuSection, MenuSidebar },

    props: {
        menu: {
            type: Object,
            required: true,
        },
        downloads: {
            type: Array,
            default: () => [],
        },
        images: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        getSectionImage(id) {
            const image = this.images.find((image) => image.section == id);

            return image ? image : null;
        },
    },
};
</script>
