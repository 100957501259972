<template>
    <div>
        <div class="menu-section border-top py-2">
            <div class="bg-white py-4 py-md-6 px-4 px-md-7">
                <div class="mb-4 mb-md-5">
                    <div class="menu-section-title text-yellow text-uppercase">{{ section.name }}</div>
                    <div v-if="section.description" class="opacity-50">
                        {{ section.description }}
                    </div>
                </div>

                <div class="menu-items grid">
                    <MenuItem v-for="item in section.items" :item="item" :key="'item-' + item.id"></MenuItem>
                </div>
            </div>
        </div>

        <div v-if="image" class="menu-section border-top py-2">
            <img :src="image.image.url" :alt="image.image.alt" class="w-100 h-auto" />
        </div>
    </div>
</template>

<script>
import MenuItem from './Item.vue';

export default {
    components: { MenuItem },

    props: {
        section: {
            type: Object,
            required: true,
        },
        image: {
            type: Object,
            default: null,
        },
    },
};
</script>
