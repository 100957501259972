import Glide, { Controls, Breakpoints, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm';

export default class {
    constructor() {
        this.el = document.querySelector('.home-masthead .glide');
        this.glide = null;

        if (this.el) {
            this.mount();
        }
    }

    mount() {
        this.glide = new Glide('.glide', {
            autoplay: 6000,
        }).mount({ Controls, Breakpoints, Swipe, Autoplay });
    }
}
