<template>
    <div class="d-flex flex-column justify-content-between h-100">
        <div class="flex-grow-1" id="menu-sidebar-track" ref="menuSidebarTrack" data-sticky-container>
            <div class="menu-sidebar pb-4 pb-xl-8" data-margin-top="16">
                <div class="menu-nav w-100 d-none d-xl-block" ref="menuNav">
                    <div class="h5 text-yellow text-center mb-4">Our Menu</div>

                    <nav class="menu-nav-items row flex-column g-0" id="menu-nav-items">
                        <div v-for="section in menu.sections" class="menu-nav-item position-relative text-center">
                            <a :href="'#' + section.slug" class="menu-nav-link d-inline-block text-decoration-none text-uppercase">
                                {{ section.name }}
                            </a>
                        </div>
                    </nav>
                </div>

                <div v-if="downloads.length" class="menu-downloads w-100 mt-6 mt-xl-8">
                    <div class="h5 text-yellow text-center mb-4">Download Menus</div>

                    <div class="row flex-column justify-center flex-column gy-2 mx-auto">
                        <div v-for="download in downloads">
                            <a :href="download.button.url" target="_blank" rel="noopener" class="btn btn-outline-primary d-block">
                                {{ download.button.label }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pb-6 d-none d-xl-block">
            <a href="#top" class="menus-to-top d-flex flex-column align-items-center justify-content-center">
                <div class="menus-to-top-arrow border rounded rounded-circle d-flex">
                    <inline-svg src="/app/themes/littles/images/arrow.svg"></inline-svg>
                </div>

                <span class="menus-to-top-text text-uppercase mt-2">Back to the top</span>
            </a>
        </div>
    </div>
</template>

<script setup>
import StickyJs from 'sticky-js';
import { onMounted } from 'vue';

const props = defineProps({
    menu: { type: Object, required: true },
    downloads: { type: Array, default: () => [] },
});

onMounted(() => new StickyJs('.menu-sidebar'));
</script>
