import Glide, { Controls, Breakpoints, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm';

export default class {
    constructor() {
        this.el = document.querySelector('.press-carousel');
        this.glide = null;

        if (this.el) {
            this.mount();
        }
    }

    mount() {
        this.glide = new Glide('.press-carousel', {
            perView: 3,
            bound: true,
            peek: 64,
            autoplay: 7000,
            type: 'carousel',
            breakpoints: {
                1300: {
                    perView: 2,
                },
                960: {
                    perView: 1,
                },
            },
        }).mount({ Controls, Breakpoints, Swipe, Autoplay });
    }
}
