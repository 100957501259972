import '../styles/littles.scss';

import { createApp } from 'vue';
import InlineSvg from 'vue-inline-svg';
import { Collapse, ScrollSpy } from 'bootstrap';
import homeMasthead from './modules/home-masthead';
import LittlesMenu from './components/Menu/Menu.vue';
import ReservationsButton from './components/Reservations/Button.vue';

// import PappasLeadsForm from '../../../pappas-leads-form/src/index';
import PappasLeadsForm from '@pappas/leads-form';
import PappasEclub from '@pappas/eclub';
import pressCarousel from './modules/press-carousel';

const app = createApp({
    data() {
        return {
            homeMasthead: null,
            pressCarousel: null,
        };
    },
    mounted() {
        this.homeMasthead = new homeMasthead();
        this.pressCarousel = new pressCarousel();
    },

    methods: {
        toggleNav() {
            document.querySelector('body').classList.toggle('nav-open');
        },
    },
});

app.config.productionTip = false;

app.use(PappasLeadsForm);

app.use(PappasEclub, {
	concept: 'pappas-burger',
	apiBase: import.meta.env.DASHBOARD_API_BASE,
});

app.component('inline-svg', InlineSvg);
app.component('littles-menu', LittlesMenu);
app.component('reservations-button', ReservationsButton);

app.mount('#app');
