<template>
    <div class="resy-widget py-7 text-center mx-auto">
        <a :href="href" :id="id" class="btn btn-primary text-white">Book your reservation on Resy</a>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        href: {
            type: String,
            required: true,
        },
    },

    mounted() {
        resyWidget.addButton(document.getElementById('resyButton-ZCN2jlrabAxjaem_NURUT'), {
            venueId: 70900,
            apiKey: 'UXALfIcSajBzJKUQUfgCVCAxERDIJxvG',
            replace: true,
        });
    },
};
</script>
